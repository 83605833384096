$(function () {
  var headerHight = 0;
  $('a[href^=#]').click(function(){
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top-headerHight;
    $("html, body").animate({scrollTop:position}, 400, "swing");
    return false;
  });
});

//sp menu
$(function(){
  $('.menu-trigger, .header-nav-item').click(function() {
    $('.menu-trigger').toggleClass('active');
    $('.header-nav').toggleClass('active');
    $('body,html').toggleClass('active');
  });
});
